.it__solutions__container__2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.it__solutions__item__2 {
    padding: 3rem;
    background-color: #e0e1dd;
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.it__solutions__item__2 h2,
.it__solutions__item__2 p {
    margin-bottom: 1rem;
}

.it__solutions__item__2 a {
    width: 150px;
    text-align: center;
    text-decoration: none;
    background-color: #ee9b00;
    color: #fff;
    padding: .5rem 1.5rem;
    transition: .3s;
}

.it__solutions__item__2 a:hover {
    background-color: chocolate;
}

.it__solutions__img__2 {
    background-color: #e0e1dd;
}

.it__solutions__img__2 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media screen and (max-width:801px) {
    .it__solutions__container__2 {
        display: flex;
        flex-direction: column;
    }

}

@media screen and (max-width: 456px) {
    .it__solutions__item__2 {
        padding: 1rem;
    }

}






