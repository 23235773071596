.services__last__container__div__1 {
    margin: 1rem 1rem 0;
    padding: 3rem;
    text-align: center;
    background-color: #f4f1de;
    transition: .3s;
}

.services__last__container__div__1:hover {
    box-shadow: 3px 3px 5px;
}

.services__last__container__div__1 h2{
    margin-bottom: 2rem;
}

.services__last__container__div__2 {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 1rem ;
}


.services__last__container__div__2 div {
    flex: 1 0 290px;
    background-color: #f4f1de;
    padding: 1rem;
    transition: .3s;
}

.services__last__container__div__2 div:hover {
    box-shadow: 3px 3px 5px;
}

.services__last__container__div__2 div h3{
    margin-bottom: 1rem;
}

.services__last__container__div__2 div p{
    margin-bottom: 1rem;
}














