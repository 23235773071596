.footer{
    
    background-color: #001219;
    color: #fff;
    padding: 2rem;
}

.footer__all__items__container {
    display: flex;
    justify-content: space-between;
}

.footer__logo {
    margin-bottom: 1rem;
    font-size: 1.5rem;
    color: #ffb703;
    display: flex;
    align-items: center;
}

.footer__logo img {
    width: 55px;
    height: 55px;
    margin-right: 1rem;
    border-radius: 50px;
}

.footer__contact__info {
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.footer__contact__info p a{
    color: #fff;
}

.footer__links {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    margin: 1rem 0;
}

.footer__links a{
    color: #ffb703;
    line-height: 1.7rem;
}

.footer__links a:hover{
    color: #ff6d00;
}

.footer__date__priv {
    border-top: solid #fff 1px;
    padding-top: 1rem;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.footer__date__priv a{
    color: #ff6d00;
}

.footer__btns__1 {
    width: 145px;
    margin-top: 1rem;
    padding: .3rem 1.5rem;
    text-align: center;
    border: solid 2px #ffb703;
    color: #ffb703;
    text-decoration: none;
    transition: .3s;
    display: block;
}

.footer__btns__1:hover {
    background-color: #ffb703;
    color: #fff;
}

.footer__btns__2 {
    width: 145px;
    margin-top: 1rem;
    padding: .3rem 1.5rem;
    text-align: center;
    text-decoration: none;
    background-color: #ffb703;
    color: #fff;
    transition: .3s;
}

.footer__btns__2:hover {
    background-color: #ff6d00;
    
}

/* .footer__btns__2__coffe{
    width: 200px;
} */

details:hover {
    cursor: pointer;
}

@media screen and (max-width:789px) {
    .footer__all__items__container {
        flex-direction: column;
    }
    .footer__date__priv {
        flex-direction: column;
        
    }
    .footer__date__priv a{
        
        border-bottom: none;
    }

}


@media screen and (max-width: 456px) {
    .footer__logo {
        font-size: 1rem;
    }

}






























