.it__solutions__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.it__solutions__item {
    padding: 3rem;
    background-color: #e0e1dd;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.it__solutions__item h2,
.it__solutions__item p {
    margin-bottom: 1rem;
}

.it__solutions__item a {
    width: 150px;
    text-align: center;
    text-decoration: none;
    background-color: #ee9b00;
    color: #fff;
    padding: .5rem 1.5rem;
    transition: .3s;
}

.it__solutions__item a:hover {
    background-color: chocolate;
}

.it__solutions__img {
    background-color: #e0e1dd;
}

.it__solutions__img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media screen and (max-width:801px) {
    .it__solutions__container {
        display: flex;
        flex-direction: column-reverse;
     
    }
}

@media screen and (max-width: 456px) {
    .it__solutions__item {
        padding: 1rem;
    }
}








