nav {
    background-color: #001219;
    display: flex;
    justify-content: space-between;
    padding: .5rem 3rem;
    position: absolute;
    width: 100%;
    height: 65px;
    position: fixed;
    z-index: 999;
  }

  .logo {
    font-size: 2rem;
    display: flex;
    position: relative;
    top: 10px;
    height: 85px;
  }

  .logo img {
    width: 85px;
    border-radius: 50%;
  }

  .link__container__all {
    display: flex;
    align-items: center;
  }

  .link__container__all ul {
    display: flex;
    list-style-type: none;
  }

  .link__container__all ul li a {
    margin-right: 2rem;
    text-decoration: none;
    color: #fff;
  }

  .link__container__all ul li a:hover {
    color: chocolate;
  }

  .ttu__btn{
    background-color: #fca311;
      border: none;
      color: #fff;
      padding: .3rem 1.3rem;
      text-decoration: none;
      transition: .5s;
    }

  .ttu__btn:hover{
      background-color: rgb(255, 106, 0);
      color: #fff;
    }

  .hamburger__menu {
    position: relative;
    display: none;
    color: #fff;
    font-size: 1.7rem;
    left: 30px;
  }

  .hamburger__menu:hover {
    cursor: pointer;
  }

  @media screen and (max-width: 987px) {
    .link__container__all {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 0vh;
      -webkit-transform: scale(1, 0);
      transform-origin: top;
      transition:  ease-in-out 500ms;
      width: 100vw;
      top: 0;
      left: 0;
      overflow: hidden;
      background: rgba(0, 0, 0, 0.9);
      z-index: 888;
    }

    .link__container__all__show__menu {
        height: 100vh;
        -webkit-transform: scale(1, 1);
   
    }

    .link__container__all ul {
      display: flex;
      flex-direction: column;
      list-style-type: none;
    }

    .link__container__all ul li a {
      margin-right: 0;
      color: #fff;
      line-height: 3rem;
      letter-spacing: 1rem;
    }

    .hamburger__menu {
      display: flex;
      align-items: center;
      z-index: 998;
    }

    .menuToggleColor {
      color: #fff;
    }

    .ttu__btn{
      border: none;
      background-color: chocolate;
      color: white;
      padding: .3rem 1.3rem;
      margin-top: 3rem;
    }

  }

@media screen and (max-width: 456px) {
  .logo {
    font-size: 1rem;
    display: flex;
    position: relative;
    top: 10px;
    right: 30px;
    height: 65px;
    width: 65px;
  }

  .logo img {
    width: 85px;
    border-radius: 50%;
  }  
}














