.contact__landing__div__1 {
    padding: 5rem 1rem 1rem;
    margin-bottom: 1.5rem;
    text-align: center;
}
.contact__landing__div__1 h1{
    padding: 2rem;
}
.contact__landing__div__1 p{
    margin-bottom: 2rem;
}
.contact__landing__div__1 a{
    margin-bottom: 2rem;
    padding: .5rem 1.5rem;
    text-decoration: none;
    font-weight: 500;
    background-color: #fca311;
    color: #fff;
    transition: .3s;
}
.contact__landing__div__1 a:hover{
    background-color: chocolate;
}





















