.landing__img {
    width: 100%;
    height: 70vh;
    background-image: url('../imgs/deskComputersOpenSign.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover ;
    overflow: hidden;
}

.landing__img__desc {
    background-color: #001219;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 3rem 1rem;
}

.landing__img__desc h1 {
    text-transform: uppercase;
}

.landing__img__desc  {
    text-transform: capitalize;
}

.landing__img__desc a {
    border: solid 2px #ee9b00;
    color: #ee9b00;
    text-transform: uppercase;
    text-decoration: none;
    padding: .5rem 1.5rem;
    margin-top: 2rem;
    transition: .5s;
}

.landing__img__desc a:hover{
   
    background-color: #ee9b00;
    color: #fff;
}

.home__container {
    padding: 3rem;
    background-color: #e0e1dd;
    color: #001219;
}

.home__container h1 {
    border-bottom: #001219 2px solid;
    font-size: 2rem;
}

.home__container h2 {
    margin-top: 1rem;
}

.home__container ul {
    margin-left: 1.5rem;
}

.imgs__devider__container {
    width: 100%;
    /* height: 35vh; */
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.imgs__devider__item__div img{
   width: 100%;
   
}

/* review section below */
.reviews {
    font-family: Arial, sans-serif;
    max-width: 90%;
    margin: 3rem auto;
    padding: 20px;
    border: 1px solid #ccc;
    background-color: #e0e1dd;
    border-radius: 8px;
    display: flex;
  }
 
.testimonial__h2 {
    text-align: center;
    padding: 2rem 1rem 0;
    letter-spacing: 1rem;
    text-transform: uppercase;
    color: #1b263b;
}
  .review {
    margin-bottom: 20px;
    border-bottom: 1px solid #ccc;
    padding: 10px ;
  }

  .review img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
  }

  .rating {
    font-size: 18px;
    color: #f8b400;
  }

@media screen and (max-width: 765px) {
    .reviews{
        display: flex;
        flex-direction: column;
    }
}

@media  screen and (max-width: 456px) {
    .home__container {
        padding: 1rem;
    }
    .testimonial__h2 {
        letter-spacing: .5rem;
    }
}