.about__landing__img__container {
    position: relative;
    width: 100%;
    height: 45vh;
    background-image: url('../imgs/coding-css.jpg');
    background-size: cover;
}

.about__landing__img__container::after{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.about__desc__item__1 {
    padding: 3rem 1rem;
    text-align: center;
    color: #fff;
    background-color: #001219;
}

.about__desc__item__1 h1{
    margin-bottom: 1rem;
    text-transform: uppercase;
 
}

.about__desc__item__1 p{
    margin-bottom: 1.5rem;
}

.about__desc__item__1 a{
    padding: .5rem 1.5rem;
    border: none;
    text-decoration: none;
    background-color: #fca311;
    color: white;
    font-weight: 700;
    transition: .3s;
}

.about__desc__item__1 a:hover {
    background-color: #f35b04;
}

.about__desc__item__2 {
    padding: 3rem 1rem;
    margin: 1rem;
    background-color: #e0e1dd;
    color: #274c77;
}
.about__desc__item__2 h2{
    margin-bottom: 1rem;
}
.about__desc__item__2 p{
    margin-bottom: 1rem;
}

.about__desc__container__last {
    padding:0 1rem 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px;
}

.about__desc__container__last__item {
    background-color: #e0e1dd;
    color: #274c77;
    padding: 1rem;
    flex: 1 0 350px;
}

.about__desc__container__last__item h2{
    margin-bottom: 2rem;
}












