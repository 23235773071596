
.services__landing__container {  
    width: 100%;
    padding: 7rem 0 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    overflow: hidden;
}

.services__landing__container div{
    width: 50%;
}

.services__landing__container h1 {
    margin-bottom: 2rem; 
}

.services__landing__container p {
    margin-bottom: 2rem; 
}

.services__landing__container a {
    padding: .5rem 1.5rem;
    text-decoration: none;
    background-color: #ec9a29;
    color: #fff; 
    transition: .3s;
}

.services__landing__container a:hover {
    background-color: #fb6107;
}



@media screen and (max-width: 789px) {

    .services__landing__container div{
        width: 90%;
    }
    
    .services__landing__container h1 {
        margin-bottom: 1rem; 
        font-size: 1.5rem;
    }
    
    .services__landing__container p {
        margin-bottom: 2rem; 
    }
    
    .services__landing__container a {
        margin-bottom: 1rem;
    }

}







