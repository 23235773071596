.contact__form__container {
    position: relative;
    background-image: url('../../imgs/laptop\ dual\ monitor.jpg');
    background-size: cover;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 1rem 0 3rem;
    display: flex;
    flex-direction: column;
    
}

.contact__form__container::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 2;
}

.contact__form__container h2 {
    padding: 2rem;
    text-transform: uppercase;
    letter-spacing: .3rem;
    font-size: 2rem;
    color: #fff;
    z-index: 3;
}

.input__container__all {
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 3;
}

.input__container__all__first__div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contact__form__container input {
    border: none;
    margin: 0 0 10px 0;
    padding: 1rem 1.5rem;
    width: 50%;
    background-color: rgba(0, 0, 0, 0.2);
    color: #fff;
}

.contact__form__container textarea {
    width: 50%;
    padding: 1rem 1.5rem;
    margin-bottom: 5px;
    border: none;
    background-color: rgba(0, 0, 0, 0.3);
    color: #fff;
}

.contact__form__container button {
    padding: .5rem 1.5rem;
    margin: 5px 1rem 1rem;
    width: 50%;
    border: none;
    font-size: 1.2rem;
    letter-spacing: .3rem;
    background-color: #fca311;
    color: #fff;
    transition: .3s;
}

.contact__form__container button:hover{
    background-color: chocolate;
}

@media screen and (max-width:690px) {
    .contact__form__container h2 {
        padding: 1rem;
    }

    .contact__form__container input {
        width: 90%;
    }
    
    .contact__form__container textarea {
        width: 90%;
    }

    .contact__form__container button {
        width: 90%;
    }
}












