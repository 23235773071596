.contact__coverage {
    padding: 3rem 1rem;
}

.contact__coverage h2{
    margin-bottom: 1rem;
}

.contact__coverage p{
    margin-bottom: 1rem;
}

.contact__coverage a{
    padding: .5rem 1.5rem;
    border: solid 3px #fca311;
    color: #fca311;
    text-decoration: none;
    font-weight: 700;
    transition: .3s;
}

.contact__coverage a:hover{
    background-color: #fca311;
    color: white;
}






















